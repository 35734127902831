<template>

            <form @submit.prevent="save">
                <div class="form-group">
                    <label for="name">Nombre</label>
                    <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Nombre del recurso">
                </div>

                <div class="form-group">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
            </form>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';

export default {
    routeName:'funds',
    name: 'formFund',
    components:{
        submitbutton
    },
    props:{

        id:{
            type:Number,
            required:false,
        }

    },  
    data(){

        return {
            
            model:{
                id:'',
                name:'',
            },
            
            loading:false,

        }

    },

    methods:{


        selectFile(event) {
         this.model.icon = event.target.files[0];
        },

        save(){

            
            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    
    
    mounted(){

        




        if(!this.isNewRecord){
                api.get(this.me(this.$props.id))
                .then(response=>{
                    
                        const data=response.data;

                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }


                        
                        


                }).catch(error=>{
                    console.log(error);
                });

        }



    }


}

</script>